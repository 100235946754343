document.addEventListener("DOMContentLoaded", (e) => {
    toggle_list = document.querySelectorAll('.js-toggle-list');

    toggle_list.forEach(tlist => {
        tlist.addEventListener('click', (e) => {
            list = e.srcElement.parentElement.nextElementSibling;

            if(list.classList.contains('hidden')){
                list.classList.remove('hidden');
            } else {
                list.classList.add('hidden');
            }

            e.preventDefault();
        });
    });
});