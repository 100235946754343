function prepareComponents(){
  let notificationsType = document.querySelector('input[name="connection[notify_by_component]"]:checked').value;
  let components = document.getElementsByClassName('component');

  for(let component of components){

      let name = component.getElementsByClassName('name')[0];
      let check = component.getElementsByClassName('check')[0];

      if( notificationsType == 'every' ) {
          name.classList.remove('w-11/12')
          name.classList.add('w-full');
          check.classList.add('hidden');
      } else {
          name.classList.remove('w-full')
          name.classList.add('w-11/12');
          check.classList.remove('hidden');
      }
  }
}

document.addEventListener("DOMContentLoaded", (e) => {
  let radios = document.getElementsByName('connection[notify_by_component]');

  if(radios.length == 0){
      return;
  }

  prepareComponents()

  radios.forEach(radio => {
    radio.addEventListener('change', function() {
        prepareComponents()
    });
  });

  let parent_components = document.getElementsByClassName('cparent');

  for(let parent of parent_components){
    parent.addEventListener('change', function() {
        children = document.querySelectorAll("[data-parent='"+parent.value+"']")
        for(let child of children){
            child.checked = parent.checked;
        }
    });
  };

  let children_components = document.getElementsByClassName('cchild');

  for(let child of children_components){
    child.addEventListener('change', function() {
        parent = document.getElementById("connection_components_ids_"+child.getAttribute('data-parent'))
        if(!child.checked){
            parent.checked = false
        }
    });
  };
});