// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require('./notifications')
require('./services')
require('./service_show')

document.addEventListener("DOMContentLoaded", (e) => {
    let close_popular = document.getElementById('js-close-popular');

    if(close_popular){
        close_popular.addEventListener('click', (e) => {
            document.cookie = 'close_popular=true; expires=Sun, 1 Jan 2030 00:00:00 UTC; path=/';
            document.getElementById('popular-services').remove();
        });
    }
});

// open/close menu
document.addEventListener("DOMContentLoaded", (e) => {
  let openMenu = document.getElementById('js-open-menu');
  let closeMenu = document.getElementById('js-close-menu');
  let sidebarMenu = document.getElementById('js-sidebar-menu');

  if(openMenu){
    openMenu.addEventListener('click', (e) => {
      sidebarMenu.classList.remove('left-[-280px]');
      sidebarMenu.classList.add('left-0');
    });
  }

  if(closeMenu){
    closeMenu.addEventListener('click', (e) => {
      sidebarMenu.classList.remove('left-0');
      sidebarMenu.classList.add('left-[-280px]');
    });
  }
})

document.addEventListener("DOMContentLoaded", (e) => {
  toggles = document.querySelectorAll('.js-toggle-button');

  toggles.forEach((toggle) => {
    toggle.addEventListener('click', (e) => {
      let target = e.target;
      let target_span = target.children[0];

      if(target.classList.contains('bg-gray-400')){ //disabled -> enabled
        target.classList.remove('bg-gray-400');
        target.classList.add('bg-blue-600');
        target_span.classList.remove('translate-x-0');
        target_span.classList.add('translate-x-5');

        setCookie('show_only_impacting', 'true', 365);
        toggleOnlyImpacting(true);
      } else { //enabled -> disabled
        target.classList.remove('bg-blue-600');
        target.classList.add('bg-gray-400');
        target_span.classList.remove('translate-x-5');
        target_span.classList.add('translate-x-0');

        setCookie('show_only_impacting', 'true', -1);
        toggleOnlyImpacting(false);

      }
    })
  });
});

function toggleOnlyImpacting(result){
  let outages = [].slice.call(document.getElementById('ongoing-outages').children);
  outages.forEach((child) => {
    if(result){
      if(!child.classList.contains('js-impact')){
        child.classList.add('hidden');
      }
    } else {
      child.classList.remove('hidden');
    }
  });
}

function getCookie(cname) {
  let name = cname + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(';');
  for(let i = 0; i <ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

function setCookie(cname, cvalue, exdays) {
  const d = new Date();
  d.setTime(d.getTime() + (exdays*24*60*60*1000));
  let expires = "expires="+ d.toUTCString();
  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

